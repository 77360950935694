.and {
  display: flex;
  width: 100%;
  justify-content: space-between;
  max-height: 100vh;
}
.tableBox {
  width: 100%;
  height: 100vh;
  background-color: #f2f5f9;
}
.navbar {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 60px;
  padding: 10px 15px;
  width: 82%;
  position: fixed;
  z-index: 1;
  float:right;
  overflow: hidden;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
}
.leftNavbar {
  display: flex;
  margin-left: 16px;
  margin-top: 6px;
}
.leftNavbar > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.leftNavbar > span > img {
  height: 45px;
  width: 45px;
}
.leftNavbar > span > label {
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}
.testDetails {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.testDetails > label {
  width: 45%;
  font-size: 17px;
  margin: 0px 10px;
}
.mockDetails {
  width: 265px;
  display: flex;
  height: 100vh;
}
.rightNavbar {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.rightNavbar > div {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: #c9c6c6;
  cursor: pointer;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.btns {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  /* height: 60px; */
}
.btns button {
  padding: 2px 9px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  background-color: rgb(102, 102, 228);
  font-weight: bold;
  color: white;
}
.btns button img {
  padding-right: 5px;
}
.btns button:hover {
  background-color: rgb(116, 8, 216);
  color: white;
}
.avaratIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-top: 20px;
}
.avatar {
  display: flex;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  margin: auto;
}
.info label:nth-child(1) {
  font-size: 24px;
  margin: -30px auto 2px auto;
  text-align: center;
  color: rgb(10, 2, 39);
}
.info label {
  margin: 5px;
  font-family: 600;
  text-align: left;
  width: 90%;
  font-size: 18px;
}
.info label span {
  color: blue;
}
.navigation {
  display: flex;
  height: 50px;
  align-items: center;
  margin-top: 60px;
  width: 100%;
  justify-content: space-between;
  margin-left: 30px;
}
.deleteBtns {
  height: auto;
  margin: auto 30px auto auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deleteBtns button {
  padding: 2px 9px;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 15px;
  color: rgb(2, 6, 41);
  border: 1px solid blueviolet;
  font-weight: 500;
  margin: 0px 15px;
  transition: all 0.3s ease;
}
.deleteBtns button:hover {
  transition: all 0.3s ease;
  background-color: rgb(208, 174, 241);
  border: 1px solid rgb(230, 9, 9);
  color: rgb(55, 9, 180);
}
.deleteBtns button img {
  width: 20px;
  margin: auto;
  height: 20px;
  padding-right: 5px;
}

.homebutton {
  cursor: pointer;
  color: gray;
}
.mocklabel {
  color: gray;
}
.homebutton:hover {
  text-decoration: underline;
}

/* .main {
  max-height: 100vh;
  width: 95%;
  margin: 90px auto auto auto;
}
.innerContainer {
  width: 100%;
  display: flex;
  height: 100vh;
  background-color: rgba(248, 248, 248, 1);
  justify-content: space-around;
}
.mockDetails {
  width: 20%;
  background-color: #eeeeee;
  height: 80vh;
  position: fixed;
  left: 50px;
  top: 120px;
  border-radius: 15px;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.btns {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  height: 60px;
}
.btns button {
  padding: 2px 9px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  background-color: rgb(102, 102, 228);
  font-weight: bold;
  color: white;
}
.btns button img {
  padding-right: 5px;
}
.btns button:hover {
  background-color: rgb(116, 8, 216);
  color: white;
}
.avaratIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-top: 20px;
}
.avatar {
  display: flex;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  margin: auto;
}
.info label:nth-child(1) {
  font-size: 24px;
  margin: -30px auto 2px auto;
  text-align: center;
  color: rgb(10, 2, 39);
}
.info label {
  margin: 5px;
  font-family: 600;
  text-align: left;
  width: 90%;
  font-size: 18px;
}
.info label span {
  color: blue;
}
.search {
  height: 50px;
  width: 71.5%;
  position: fixed;
  background-color: #e3e9f0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.search label input {
  border-radius: 15px;
  margin-left: 10px;
  appearance: none;
  width: 85%;
  padding: 3px 0px 3px 18px;
  cursor: pointer;
  border: none;
  background-image: url("../../icons/searchIcon.svg");
  background-repeat: no-repeat;
  background-position: 90%;
  background-position-y: center;
  text-align: left;
  background-position-x: 90%;
}
.search label input:focus {
  outline: none;
}
.search label select {
  border: none;
  border-radius: 20px;
  width: 180px;
  outline: none;
  padding: 3px 30px 3px 15px;
  cursor: pointer;
  appearance: none;
  background-image: url("../../icons/dropDownArrow.svg");
  background-repeat: no-repeat;
  background-position: 90%;
  background-position-y: center;
  text-align: center;
  background-position-x: 155px;
  color: #a8a8a8;
  margin: auto 5px;
}

.search label select:focus {
  outline: none;
}
.stdDetails {
  width: 71.5%;
  position: fixed;
  top: 170px;
  background-color: white;
}
.stdDetails ul {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  list-style: none;
  margin-top: 9px;
}
.stdDetails ul input {
  width: 29px;
  height: 29px;
  position: relative;
  left: 33px;
}
.stdDetails ul li {
  width: 25%;
  text-align: center;
  font-size: 19px;
  font-weight: 600;
}
.table {
  height: 85vh;
  width: 71.5%;
  margin-left: 22.5%;
  overflow: auto;
  position: fixed;
}
.mainTable {
  width: 100%;
  float: right;
  margin-top: 100px;
  margin-left: 20%;
  overflow: auto;
}
.stdMenu {
  width: 100%;
}
.tbody {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: aliceblue;
}
.tbody tr:nth-child(odd) {
  background-color: rgb(255, 255, 255);
  font-size: 18px;
}
.tbody tr:nth-child(even) {
  background-color: rgb(255, 255, 255);
  font-size: 18px;
}
.tr {
  width: 100%;
  display: flex;
  padding: 3px 2px;
  align-items: center;
  border-bottom: 1.5px solid black;
  height: 45px;
  padding: 10px 0px;
  justify-content: space-between;
}
.tr:hover {
  background-color: rgb(234, 245, 245);
  cursor: pointer;
}
.td {
  width: 25%;
  text-align: left;
  display: flex;
  align-items: center;
  margin-left: 33px;
  justify-content: flex-start;
}
.tr label {
  width: 25px;
}
.pass {
  background-color: rgb(232, 235, 232);
  color: rgb(10, 171, 66);
}
.fail {
  background-color: rgb(239, 238, 238);
  color: rgb(228, 28, 28);
}
.reviwed {
  color: green;
}
.attempted {
  color: blue;
}
.tr label input {
  height: 20px;
  width: 20px;
  cursor: pointer;
  margin-right: 25px;
}
.td span {
  border-radius: 10px;
  padding: 2px 7px;
  font-size: 14px;
  font-weight: 600;
}
.deleteBtns {
  width: 100%;
  height: auto;
  display: flex;
  border-radius: 10px;
  justify-content: space-around;
}
.deleteBtns button {
  padding: 2px 9px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  background-color: rgb(219, 110, 67);
  color: white;
  font-weight: 500;
}
.deleteBtns button img {
  width: 20px;
  margin: auto;
  height: 20px;
  padding-right: 5px;
}

.homebutton {
  cursor: pointer;
  color: gray;
}
.mocklabel {
  color: gray;
}
.homebutton:hover {
  text-decoration: underline;
} */

@media (max-width: 1000px) {
  .rightMockTest {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    background-color: #f2f5f9;
    border-radius: 10px;
    overflow-y: auto;
  }
  .rightNavbar {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .deleteBtns button {
    padding: 2px 5px;
    font-size: 13px;
    margin: 0px 5px;
  }
  .navigation {
    font-size: 13px;
    margin-left: 20px;
  }
 
  .mockDetails {
    width: 0%;
  }
}
