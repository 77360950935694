.customModal .modal-content {
  border-radius: 0; /* Remove rounded corners */
}
.customModal {
  border-radius: 0;
  box-shadow: 0px 14px 18px rgba(0, 0, 0, 0.1);
  /* max-width: 440px;
  align-self: center; */
}
.createacc:hover {
  cursor: pointer;
  text-decoration: underline;
}

.modalTitle {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: Poppins,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  display: flex;
  gap: 14px;
  justify-content: center;
  align-items: center;
  font-weight:500;
}

.modalSubtitle {
  text-align: center;
  margin-bottom: 12px;
  font-family: Poppins,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight:300;
}

.inputField {
  width: 100%;
  padding: 10px;
  padding-inline: 6px;
  border-style: none;
  border: 1px solid #dfe1e6;
 border-width: 2px;
}

.loginButton {
  width: 100%;
  background-color: #0c66e4;
  padding: 11px;
  text-align: center;
  color: aliceblue;
  font-weight: 400;
  border-radius: 3px;
  border-style: none;
}

.loginButton:hover {
  cursor: pointer;
}

.continueWith {
  display: flex;
  width: 78%;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 18px;
  border: 1px solid rgb(193,199,208);
  border-radius: 3px;
  margin-bottom: 24px;
  cursor: pointer;
}

.continueWith:hover{
  box-shadow: 0px 1px 1px gray;
}

.digiKullOptions {
  width: 78%;
  margin: auto;
  display: flex;
  justify-content: center;
  gap: 18px;
  cursor: pointer;
}

/* .digiKullOptions:hover{
  text-decoration: underline;
} */

.digiKullLogo {
  width: 29px;
  height: 29px;
}

.digiKullText {
  color: grey;
  font-family: Poppins,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.footerLinks {
  width: 78%;
  display: flex;
  margin: auto;
  justify-content: center;
  gap: 15px;
  margin-bottom: 17px;
}

.privacyPolicy {
  font-size: 12px;
  text-align: center;
}

.userNotice {
  font-size: 12px;
}

.googleProtection {
  font-size: 12px;
  text-align: center;
}

.googleProtectionLink {
  text-decoration: none;
}
