@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.practice_label {
  font-size: large;
  font-weight: 600;
  color: white;
}

.home_label {
  cursor: pointer;
  color: white;
}

.home_label:hover {
  font-size: 15px;
}

.heading {
  border-radius: 5px;
  height: 40px;
  width: 90%;
  margin: 12px auto 0 auto;
  background-color: white;
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.codeeditor {
  height:76vh;
  /* margin-top: 3px; */
  border: 1px solid;
  /* border-radius: 5px; */
  margin-left: 5px;
  width: 100%;
}

.files_group {
  /* width: 300px; */
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  /* justify-content: space-between; */
  /* margin-left: 15px; */
  padding: 0.4rem 0.4rem;
}
.filename {
  border: 1px solid;
  border-radius: 5px 5px 0 0;
  padding: 5px;
  border-bottom: none;
  border-collapse: collapse;
  cursor: pointer;
}

.language_Selection {
  width: 100px;
  background-color: #001529;
  color: white;
  cursor: pointer;
}

.Selection_options {
  cursor: pointer;
}

.readme {
  width: 100%;
  height: 82vh;
  color: white;
  border: 1px solid white;
  padding: 10px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  margin: 3px 5px 0 0;
  overflow: auto;
}

.right_side {
  width: 30%;

  /* height: 88vh; */
  color: white;
  /* margin-top: 0.5rem; */
}

.right_headings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  margin-left: 5px;
  margin-right: 10px;
}

.right_output {
  border: 1px solid white;
  height: 82vh;
  margin-top: 3px;
  border-left: none;
  border-radius: 5px;
}

.webview_option {
  width: 100%;
  text-align: center;
  font-size: 50px;
  margin-top: 150px;
  cursor: pointer;
}

.output {
  overflow: auto;
  height: 90%;
  /* padding: 5px; */
}

.difficulty_selection {
  width: auto;
  background-color: #001529;
  cursor: pointer;
  color: white;
}

/* .question_list {
  color: black;
  list-style-type: none;
  margin-top: 15px;
  cursor: pointer; */
  /* height: 72vh; */
  /* border: 1px solid; */
  /* overflow: auto; */
  /* padding-left: 2px;
} */

.question_list_que {
  /* margin-top: 2px; */
  /* padding: 5px 2px; */
  cursor: pointer;
  height: 100px;
  overflow: hidden;
  /* padding-left: 2px; */
  border: 0.1rem solid #e3e8e8;
}


/* Coding Window Style */

/* New Styles */
.newnavbar {
  z-index: 11;
  height: 3.8rem;
  padding: 0.8rem 1.6rem;
  border-bottom: 0.05rem solid #e3e8e8 ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* box-shadow: 0 .2rem .6rem 0 rgba(26,61,60,.05); */
}

.leftside {
    position: relative;
  width: 40%;
  height: 83vh;
  border-right: 0.1rem solid #e3e8e8;
}

.rightside {
  position: relative;
  width: 60%;
  height: 82vh;

}

.first_left_row, .first_right_row {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  /* flex-direction: column; */
  -webkit-box-pack: center;
  /* justify-content: center; */
  align-items: center;
  column-gap: 20px;
  height: 3rem;
  padding: 0.8rem 1.6rem;
  /* padding: 1rem 0; */
  border-bottom: 0.1rem solid #e3e8e8;

  font-size: 13px;
  font-family: "Roboto", "Poppins", "Gill Sans", "Gill Sans MT", Calibri,
    "Trebuchet MS", sans-serif;
  font-weight: 500;
}

.first_left_row>label:nth-child(1){
  background-color: rgb(224, 222, 222);
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
}

.first_right_row{
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.6rem 0.8rem 3.1rem;
}

.description {

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.4rem;
  color: #0d3231;
  overflow: unset;
  -webkit-box-flex: 0;
height: 100%;
/* overflow: auto; */
  /* margin: 0.8rem  1.6rem; */
  /* margin: 2rem 0; */
}


.right_side_editor{
  display: flex;
  height: 100%;
  
}


.language_icons{
  border: 0.1rem solid #ced1d1;
  padding: 0.2rem;
  font-size: 1rem;
  height: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 1.8rem;
  border-radius: 5px;
  /* transition: border-color 1s ease-out; */
}

.language_icons:hover{
  border-color:#787878 ;
}

.practice_footer{
  position: fixed;
  bottom: 0;
  font-family: Roboto,"poppins",'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1 0 0;
    width: 100%;
    padding: .8rem 1.6rem;
    box-shadow: 0 -0.2rem 1.4rem 0 rgba(26,61,60,.06);
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    overflow: hidden;
    background-color: #fff;
    line-height: 1.71rem;
    /* border-top: .1rem solid #e3e8e8; */
    z-index: 999;

}

.run_btn{
  border-style: none;
  background-color:#f1f3f3;
  border: 0.05rem solid #e3e8e8;
  border-radius: 3px;
  padding: 0.1rem 1rem;
  font-family: "Roboto","Poppins",'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 15px;
  font-weight: 400;
  width: 70px;
}
.run_btn:hover{
  border-color: rgb(126, 123, 123);
}

.footer_left{
  border: 0.1rem solid #e3e8e8;
  border-radius: 4px;
  padding: 0.1rem 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  font-size: 0.8rem;
}
.footer_left:hover{
  /* border-color: #757676; */
  background-color: lightgray;
}


.submit_btn_final{
  border-style: none;
  background-color:#08213e;
  border: 0.05rem solid #e3e8e8;
  border-radius: 3px;
  padding: 0.1rem 1rem;
  text-align: center;
  font-family: "Roboto","Poppins",'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 15px;
  font-weight: 400;
  width: 80px;
  color: white;
}

.question_render_main{
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

/* .question_render{
  border: 0.1rem solid #e3e8e8;
  border-radius: 3px;
} */

.selected_question, .unselected_question {
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* background-color: inherit; */
  padding:0.4rem ;
  border: 0.1rem solid #e3e8e8;
  border-radius: 3px;
}

.unselected_question:hover{
  border-color: black;
}

.selected_question {
 
  background-color: lightgray;
   /* border-color: black; */
}


.submitted{
  border-left-color: green;
  border-left-width: 0.2rem;
}