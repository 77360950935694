.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: calc(33.33% - 20px); /* Adjust width as needed */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.card-content {
  margin-bottom: 10px;
}


.navbar {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 60px;
  padding: 10px 15px;
  width: 81vw;
  position: fixed;
  z-index: 9;
  left: 265px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  margin-left: 16px;
}
.leftNavbar {
  display: flex;
  margin-left: 16px;
  margin-top: 6px;
}
.leftNavbar > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.leftNavbar > span > img {
  height: 45px;
  width: 45px;
}
.leftNavbar > span > label {
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}
.rightNavbar {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.rightNavbar > div {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: #c9c6c6;
  cursor: pointer;
}

.All_Data {
  margin-left: 0vw;
}

.UserData {
  width: 78vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.menu{
  cursor: pointer;
  font-weight: 500;
  font-size: 20px;
}

.dropdown_item{
  cursor: pointer;
}

@media (min-width: 800px) {
  .All_Data {
    margin-left: 18vw;
  }
}
@media (max-width: 1200px) {
  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
  }
  .All_Data {
    margin-left: 0vw;
  }
  .UserData {
    width: 92vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* background-color: aqua; */
  }

  .TopFilter {
    display: flex;
    flex-direction: column;
  }
  .navbar{
    margin-left: 0;
  }
}
@media (max-width: 590px) {
  .ManageHeading {
    align-self: center;
  }
}
