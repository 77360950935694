.header_main{
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.header_title{
      font-family: "Roboto",'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important; 
      font-size: 28px;
      font-weight: 600;
}

.loading_btn{
      font-size: 50px;
      margin-top: 50px;
}

.course_details{
      width: 95%;
      margin: 20px auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.courseTitle{
      font-size: 25px;
      font-weight: 400;
      font-family: "Roboto";
}


.curriculum_main{
      display: flex;
      width: 90%;
      margin: auto;

}

.left_side{
      width: 60%;
}

.right_side{
      
      height: 70vh;
      padding-left: 10px;
      width: 40%;       
      overflow: auto;
}

.lablename{
      font-weight: 500;
      width: 120px;
}
.lablename2{
      font-weight: 500;
     
}
.content_name{
      height: auto;
      min-height: 50px;
      display: flex;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      padding: 0 5px;
      cursor: pointer;
}
.content_name:hover{
      background-color: lightgray;
}

.details_main{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 15px     ;
}