.navbar {
      display: flex;
      justify-content: space-between;
      background: #ffffff;
      height: 60px;
      padding: 10px 15px;
      width: 82%;
      top: 0;
      position: fixed;
      z-index: 1;
      float:right;
      overflow: hidden;
      /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
    }

    .leftNavbar {
      display: flex;
      margin-left: 16px;
      margin-top: 6px;
    }
    .leftNavbar > span {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .leftNavbar > span > img {
      height: 45px;
      width: 45px;
    }
    .leftNavbar > span > label {
      font-size: 25px;
      font-weight: 600;
      cursor: pointer;
    }
    
    .rightNavbar {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }

    .rightNavbar > div {
      width: 45px;
      height: 45px;
      border-radius: 10px;
      background-color: #c9c6c6;
      cursor: pointer;
    }

    .createquestion_main{
       display: flex;
       column-gap: 100px;
       flex-wrap: wrap;
      width: 85%;
      padding:80px  50px;
      overflow:auto;
      background-color:#f2f5f9;
    }

    .titleStyle{
      width: 100%;
  border-color: black;
 
    }

    .leftside{
      width: 65%;
    }


    .descriptioneditor{
      width: 100%;
      margin-top: 30px;
      border-radius: 5px;
    }

    .rightside{
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }
    
   
    @media (max-width:1200px) {
      .navbar {
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        height: 60px;
        padding: 10px 15px;
        width: 100%;
        position: fixed;
        z-index: 1;
        left: 0;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
      }
      .leftside{
        width: 100%;
      }
      .rightside{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 15px;
        margin-top: 50px;
      }
    }

    @media (max-width:900px){
      .rightside{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 15px;
        margin-top: 100px;
      }
    }

   