.datepicker {
  border: none;
  border: 1px solid gray;
  border-radius: 3px;
  margin-left: 10px;
  width: 150px;
  cursor: pointer;
}

.namefield {
  width: 100%;
}

.common {
  font-family: Poppins;
}

.fullname:hover{
  text-decoration: underline;
  cursor: pointer;
  
}

/* personal Details */
.profile_photo{
  border-radius: 50%;
  object-fit: cover;
}

.personal_details{
  display: flex;
  column-gap: 50px;
  /* justify-content: center; */
  margin-left: 100px;
}

.rightside_personal{
  display: flex;
  flex-direction: column;
}