.warning_main {
  padding: 5vh 0 2% 0;
  width: 80vw;
  height: 85vh;
  border-radius: 15px;
  background-color: #f2f7ff;
}

.heading {
  color: #000;
  font-size: 40px !important;
  font-style: none !important;
  letter-spacing: 5px;
  line-height: normal;
}

.doneicon {
  font-size: 120px;
}
.greeting {
  font-size: 20px;
}
@media (max-width: 600px) {
  .warning_main {
    padding: 5vh 0 2% 0;
    width: 90vw;
    height: auto;
    border-radius: 15px;
    background-color: #f2f7ff;
  }
  .heading {
    color: #000;
    font-size: 27px !important;
    font-style: none !important;
    letter-spacing: 1px;
    line-height: normal;
  }
  .greeting {
    font-size: 17px;
    font-weight: 500;
  }
  .doneicon {
    font-size: 90px;
  }
 
}
