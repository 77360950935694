.AddEle {
  display: flex;
  /* justify-content: space-between; */

  /* align-items: center; */
  gap: 18px;
  min-width: 48%;
  /* background-color: green; */
}
.label:hover {
  cursor: pointer;
}
.label {
  text-decoration: underline;
}
.icon {
  margin-top: 5px;
  padding-top: 3px;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: lightgrey;
}

.innerContact {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  height: 100%;
  position: relative;
  top: 8px;
}

.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-block: 70px; */
  margin-top: 60px;
}
.steps {
  background-color: aliceblue;
  width: 45%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  row-gap: 25px;
  align-items: center;
}

.profile {
  width: 55%;
  background-color: #f2f5f9;
  padding: 30px 30px 30px 50px;
  padding-bottom: 80px;
  color: black;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* gap: 34px; */
  row-gap: 25px;
  min-height: 92vh;
  border-left: 1px solid;
}
.contactsDiv {
  margin-top: 16px;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  /* background-color: red; */
  gap: 12px;
}

.social {
  font-size: 26px;
  display: flex;
  gap: 10px;
}
.social:hover {
  cursor: pointer;
}

.profilePic {
  /* font-size: 40px; */
  background-color: gray;
  border-radius: 55%;
  padding: 15px;
  padding-inline: 23px;
  width: 90px;
  height: 90px;
  object-fit: contain;
  aspect-ratio: 3/2;
}

.profilePic:hover {
  cursor: pointer;
}

.editProfile {
  align-self: flex-start;
  text-align: right;
}
.editProfile:hover {
  cursor: pointer;
}
@media (max-width: 700px) {
  .main {
    flex-direction: column;
    width: 100vw;
  }
  .steps {
    width: 100vw;
  }
  .profile {
    width: 100vw;
    min-height: 50vh;
  }
}
