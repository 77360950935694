.header_main{
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
   
}

.left_side_course{
      width:80vw !important;
}

.header_title{
      font-family: "Roboto",'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important; 
      font-size: 28px;
      font-weight: 600;
}

.card_main{
      display: flex;
      column-gap: 20px;
      flex-wrap: wrap;
      row-gap: 20px;
      width: 90%;
      margin:20px auto;
}

.searchInput{
      
      border-color: lightgray;
      border-width: 0.5;
      width: 250px;
      padding: 0 5px;
}


