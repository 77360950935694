.curriculum_main{
      display: flex;
      width: 95%;
      margin:30px auto;

}
.left_side{
      width: 60%;
}

.right_side{
      
      height: 70vh;
      padding-left: 10px;
      width: 50%;       
      overflow: auto;
}

.details_main{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 15px     ;
}

.lablename{
      font-weight: 500;
      width: 120px;
      text-align: left;
}
.lablename2{
      font-weight: 500;
     
}
.content_name{
      height: auto;
      min-height: 50px;
      display: flex;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;
      padding: 0 5px;
      cursor: pointer;
}
.content_name:hover{
      background-color: lightgray;
}

.left_sub{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      padding: 10px;
      text-align: start;
}

.addcourse{
      cursor: pointer;
      text-decoration: underline;
      color: blue;
}

.changeorder{
      display: flex;
      justify-content: space-between;
      border: 0.5px solid lightgray;
      border-radius: 5px;
      padding: 5px;
      margin: 5px;
}

.changeorder_icon{
      display: flex;
      justify-content: flex-start;
      column-gap: 15px;
 
}