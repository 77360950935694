.main {
  width: 90%;
  margin: 85px auto auto auto;
}

.difficulties_tab {
  width: 100%;
}
.question_main {
  border-bottom: 1px solid rgba(213, 211, 211, 0.5);
  padding-top: 8px;
  font-family: "Poppins";
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.questionstyle {
  width: 40%;
  overflow: auto;
  height: auto;
  max-height: 150px;
}

.concepts {
  width: 25%;
  display: flex;
  flex-wrap: wrap;
}
.pagination {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  position: fixed;
  bottom: 50px;
  left: 55%;
}

.questionColumn {
  max-width: 40%;
}
