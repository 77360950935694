.titles{
  font-family:"Poppins";
  font-size: 15px;
}
.trainer_inputs{
      width: 100%;
      border-style: none;
      border: 1px solid gray;
      border-radius: 5px;
      height:35px;
}

.firstrow{
  margin-bottom: 10px;
  display: flex;
}
.showstatus{
  display:flex;
  align-items:center;
}
.statuslabel{
  margin-left: 5px;
  cursor: pointer;
}
.statusinput{
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.datepicker{
  border-style: none;
  border: 1px solid gray;
  border-radius: 3px;
  margin-left: 2px;
  cursor: pointer;
  width: 100px;
}

.rightMenu{
  position:fixed;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  color:black;
  width:150px;
  z-index: 9999;
}

.rightMenu:hover{
  box-shadow: 5px 3px 10px gray;
}