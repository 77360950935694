.next_btn, .prev_btn{
      border-style: none;
      
      color: white;
      font-family: "Roboto",'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-weight: 500;
      width: 80px;
      height: 2rem;
}

.next_btn{
      background-color: black;
}

.prev_btn{
      
      background-color: lightgray;
      color: black;
      
}
.header_main{
      background-color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
}

.header_title{
      font-family: "Roboto",'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif !important; 
      font-size: 28px;
      font-weight: 600;
}



.create_Main{
      display: flex;
      justify-content: center;
      padding-top: 150px;
      width: 100%;
}

.title_create_main{
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 30px;
}

.title_main{
      display: flex;
      flex-direction: column;
      align-items: center;
      /* justify-content: center; */
      row-gap: 50px;
      margin-top: 150px;
      width: 100%;
      height: auto;
      min-height: 70%;
      max-height: 80%;
}

.title_input{
      /* border-style: none; */
      border:0.5 solid;
      width: auto;
      font-family: "Poppins",Georgia, 'Times New Roman', Times, serif;
      min-width: 25rem;
      max-width: 80rem;
      height: 2.5rem;
      padding: 0.8rem;
}

.title_input:focus{
      outline: none;
      border:0.5 solid;
}

.description{
      /* border-style: none; */
      border:0.5 solid;
      width: auto;
      font-family: "Poppins",Georgia, 'Times New Roman', Times, serif;
      min-width: 30rem;
      max-width: 80rem;
      height: 10rem;
      border-radius: 5px;
      max-height: 10rem;
      padding: 0.8rem;
}

.description:focus{
      outline: none;
      border:0.5 solid;
}


.maincreate{
      display: flex;
      height: 90%;

}

.course_heading{
      font-size: 25px;
      font-family: "Poppins",'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.right_side_container{
      width: 100%;
}

.pricing{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70%;
      row-gap: 20px;
}

.preview_line{
      display: flex;
      align-items: flex-start;
      column-gap: 60px;

}

.preview_main{
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      
      overflow: auto;
}