.singleEdu, .singleExp {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* .singleExp {
  display: flex;
  justify-content: space-between;
  width: 100%;
} */

.singleEduIcons {
  display: flex;
  gap: 14px;
}

.AddExp {
  text-decoration: underline;
}
.AddExp:hover {
  cursor: pointer;
}

.singleEduIcons:hover {
  cursor: pointer;
}
@media (max-width: 700px) {
  .singleExp,
  .singleEdu {
    width: 58vw;
  }
}
@media (max-width: 450px) {
  .singleExp,
  .singleEdu {
    width: 50vw;
  }
}
