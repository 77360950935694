.maincon {
  width: 100%;
  height: 100vh;
  display: flex;
  border-radius: 15px;
  background-color: #f2f2f2;
}

.spinner {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard {
  height: auto;
  display: flex;
  width: 265px;
}
.table {
  width: 81%;
}
.navbar {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 60px;
  padding: 10px 15px;
  width: 81%;
  position: fixed;
  z-index: 1;
  left: 265px;
  overflow: hidden;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
}
.leftNavbar {
  display: flex;
  margin-left: 16px;
  margin-top: 6px;
}

.leftNavbar > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.leftNavbar > span > img {
  height: 45px;
  width: 45px;
}
.leftNavbar > span > label {
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}
.rightNavbar {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.rightNavbar > div {
  height: 45px;
  border-radius: 10px;
  background-color: #c9c6c6;
  cursor: pointer;
}
.studentsdetails {
  width: 100%;
  height: auto;
  border-radius: 13px;
  background: #ffffff;
  margin: 5px auto 5px auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
.cards {
  width: 375px;
  height: 250px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid #a8a8a8;
  background: #fff;
  margin-top: 20px;
  transition: transform 0.3s ease;
  padding: 8px;
}
.cards:hover {
  transform: translateY(-2px);
  cursor: pointer;
  box-shadow: 5px 4px 15px 0px rgba(0, 0, 0, 0.5);
}
.maincard {
  width: 90vw;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.userdetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  justify-content: center;
  height: auto;
}
.userfulldetails {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.userfulldetails label {
  font-size: 17px;
}

.usericon {
  font-size: 50px;
}
.questiontype {
  color: #a8a8a8;
  font-family: "Numnito", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.question {
  color: #000;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.statusmain {
  display: flex;
  padding: 5px;
}
.leftstatus {
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: yellowgreen;
  justify-content: space-between;
  height: 60px;
}

.statuslabels {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.status {
  color: #828282;
  padding-left: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.evaluated {
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border-style: none;
  background: green;
  color: white;
}

.notevaluated {
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;

  background: #08213e;
  border-style: none;
  color: white;
}
.option {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 0 10% 2% 10%;
}
select {
  border-radius: 5px;
  margin: 5px;
}

.feedback {
  display: block;
  width: 750px;
  /* margin-left: 14%; */
  border-radius: 5px;
  height: auto;
  max-height: 150px;
  min-height: 100px;
}
.rightNavbar {  
  display: flex ;
  align-items: center;
  margin-right: 20px;
}
.rightNavbar > div {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: #c9c6c6;
  cursor: pointer;
}
.btn {
  border: 1px solid #dc3545;
  color: #dc3545;
  border-radius: 5px;
}
.btn:hover {
  border: 1px solid #dc3545;
  color: rgb(250, 248, 248);
  background-color: #dc3545;
  border-radius: 5px;
}

.backbutton {
  margin: 61px 0 0 15px;
  cursor: pointer;
  color: gray;
}
.backbuttonlabel {
  cursor: pointer;
  border-style: none;
}


/* Slider Main */

.slider_main {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 50px 0 50px;
  height: 200px;
}
.slider_question {
  padding: 5px;
  border: 1px solid;
  width: 100%;
  border-radius: 10px;
  font-size: 15px;
}
/* .right_slider{
      width: 50%;
} */

.mcqcontainer {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
}

.feedback_container {
  margin: 120px 100px 0 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.feedback_editor {
  width: 40%;
}
.feedback_point {
  width: 10%;
}
.feedback_communication {
  width: 25%;
}
.feedback_submit {
  width: 10%;
}

@media (max-width: 1200px) {
  .rightNavbar {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    height: 60px;
    padding: 10px 15px;
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .table {
    width: 100%;
  }
}
