.userData ul {
  list-style: none;
}
.listdata {
  border: 1px solid;
  width: 96%;
  border-radius: 15px;
  display: flex;
  /* align-items: center;
  justify-content: space-between; */
  padding: 5px 15px 5px 15px;
}

.listdata:hover {
  cursor: pointer;
  box-shadow: 3px 5px lightblue;
}
.usersetting {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.usersetting input {
  border-radius: 3px;
  margin-right: 30px;
}
.menu_button {
  position: relative;
}
.Menu {
  position: absolute;
  width: 90px;
  font-size: 15px;
  right: 40px;

  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Menu label {
  padding: 5px 0 0 0;
  cursor: pointer;
  font-size: 20px;
}
.Menu label:hover {
  padding: 5px 0 0 0;
  cursor: pointer;
  font-size: 22px;
}
.visible_menu {
  visibility: visible;
}
.hide_menu {
  display: none;
}

.user_name {
  width: 40%;
}

@media (max-width: 600px) {
  .usersetting {
    flex-direction: column;
    /* background-color: blueviolet; */
    align-items: flex-start;
    gap: 20px;
  }
}

@media (min-width:1200px) {
  .usersetting{
    width: 74vw;
  }
}

