/* @import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap"); */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.App {
  overflow-x: hidden;
  overflow-y: visible;
  height: 100vh;
}

#modal {
  background-color: #0a0f4f;
}

#modal1 {
  background-image: linear-gradient(to left, #0a0f4f, yellow);
}

#nav {
  background-image: linear-gradient(to left, #0a0f4f, yellow);
  font-weight: 600;
  box-shadow: 5px 5px 5px yellowgreen;
}

button.btn-settings {
  margin: 25px;
  padding: 20px 30px;
  font-size: 1.2em;
  background-color: #337ab7;
  color: white;
}

button.btn-settings:active {
  color: white;
}

.form-control {
  font-family: "DM Sans", sans-serif !important;
}

label {
  font-family: "Poppins", sans-serif !important;
}

.sans {
  font-family: "DM Sans", sans-serif !important;
}

tr:nth-child(even) {
  background-color: lightgrey;
}

/* 
*::-webkit-scrollbar {
  width: 10px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #dfe9eb;
  cursor: pointer;
}

*::-webkit-scrollbar-track:hover {
  background-color: #b8c0c2;
  cursor: pointer;
}

*::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
  cursor: pointer;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #2b2353;
  cursor: pointer;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #2b2353;
  cursor: pointer;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #2b2353;
  cursor: pointer;
} */
