.main {
  width: 100%;
  margin: 0px auto 0 auto;
  display: flex;
}
.leftNavbar {
  display: flex;
  margin-left: 16px;
  margin-top: 6px;
}
.leftNavbar > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.leftNavbar > span > img {
  height: 45px;
  width: 45px;
}
.leftNavbar > span > label {
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}



.rightNavbar {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.rightNavbar > div {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: #c9c6c6;
  cursor: pointer;
}
.rightNavbar button {
  border-radius: 0px 5px 5px 0px;
  background: #77bfff;
  border: none;
  padding: 3.3px 8px;
}

.questions_main {
  border: 1px solid gray;
  margin-top: 15px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.question {
  font-family: "Poppins";
  margin-top: 10px;
  width: 250px;
}
.dashboard {
  display: flex;
  height: 100vh;
  width: 18% !important;
}


