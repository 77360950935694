@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@700&family=Poppins:wght@100;500&display=swap");
.main {
  margin-top: 0px;
  width: 100%;
  background-color: #f2f5f9;
}

.firstrow {
   width: 100%;
  /*margin: auto;
  display: flex;
  justify-content: space-between; */
  /* align-items: center; */
  margin-top: 70px;
  margin-bottom: 10px;
 
}

.navigator {
  color: #8c8c8c;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.navbar {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 60px;
  padding: 10px 15px;
  width: 82%;
  position: fixed;
  z-index: 9;
  left: 265px;
  overflow: hidden;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
}
.leftNavbar {
  display: flex;
  margin-left: 16px;
  margin-top: 6px;
}
.leftNavbar > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.leftNavbar > span > img {
  height: 45px;
  width: 45px;
}
.leftNavbar > span > label {
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}
.rightNavbar {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.rightNavbar > div {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: #c9c6c6;
  cursor: pointer;
}
.maintable {
  width: 100%;
  display: flex;
}
.dashboard {
  display: flex;
  height: 100vh;
  width: 18%;
}
.table {
  padding: 0px 10px;
  width: 80%;
}
@media (max-width: 1200px) {
  .table {
    padding: 0px 10px;
    width: 99%;
  }
  .rightMockTest {
    width: 100%;
    overflow: hidden;
    height: 100vh;
    background-color: #f2f5f9;
    border-radius: 10px;
    overflow-y: auto;
  }
  .rightNavbar {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    height: 60px;
    padding: 10px 15px;
    width: 100%;
    position: fixed;
    z-index: 9;
    left: 0;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  }
  .firstrow {
 
  
    margin-top: 70px;
    margin-bottom: 10px;
  }
  .mockItem {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border-radius: 9px;
    margin-left: 20px;
  }
}


.open_Question:hover{
content:"hello"
}

.upload_csv{
  cursor:pointer;
  border:1px solid #d9d9d9;
  height: 32px;
  border-radius:5px;
  padding: 3px 10px;
  text-align: center;
  
}