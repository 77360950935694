.edit_select {
  width: 82.5%;
}

.titles {
  width: 100px;
}

.questionheading {
  width: 100%;
  height: 35px;
  border-style: none;
  border: 1px solid;
  border-radius: 5px;
}

.questionheading_main {
  width: 100%;
}

.difficultyoptions {
  width: 90%;
  display: flex;
  justify-content: space-between;
}

.radiobutton {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.mcqoptions {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  width: 100%;
}

.example {
  border-radius: 10px;
  height: 20vh;
}

.testcase {
  cursor: pointer;
  margin-left: 300px;
  margin-top: 10px;
  font-weight: 600;
}

.testcase:hover {
  text-decoration: underline;
}

.editor {
  width: 100%;
  height: 40vh;
}

.testcaseinput {
  margin-left: 50px;
  padding: 2px 2px;
  width: 70%;
}

.testcaseinput2 {
  margin-left: 38px;
  padding: 2px 2px;
  width: 70%;
  box-sizing: border-box;
}

.addmore {
  font-size: 15px;
  margin-left: 15px;
  cursor: pointer;
}