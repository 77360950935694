.main {
  height: 100vh;
  width: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
  overflow: hidden;
}
.leftSide {
  width: 30%;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.leftSide > section > div {
  height: 70px;
  background-color: rgb(228, 224, 224);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.leftSide > section > div > h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto 0px;
}
.leftSide > main {
  height: auto;
  width: 90%;
  display: flex;
  margin: auto auto auto auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.leftSide > main > section {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin: 30px auto auto auto;
}
.practiceMode {
  height: 80px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 12px;
}
.leftSide > main > section > div {
  height: 80px;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 12px;
}

.leftSide > main > section > div > label {
  font-size: 14px;
}
.practiceMode > label {
  font-size: 17px;
}
.leftSide > main > section > div > p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
}
.practiceMode > p {
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins";
}
.leftSide > main > div {
  margin: 15px auto;
}
.leftSide > main > div > p {
  font-size: 35px;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.leftSide > main > div > label {
  font-size: 18px;
  font-family: "Poppins";
  text-transform: capitalize;
}
.leftSide > section > div > h3 {
  font-family: "arvo";
  font-size: 26px;
  font-weight: 600;
}
.leftSide > section > div > img {
  height: 50px;
  width: auto;
}
.rightSide {
  width: 70%;
  height: 100vh;
  overflow-y: auto;
  margin: auto;
  background-color: rgb(245, 244, 244);
}
/* Frist Section Styles */
.fristSection {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fristSection > div {
  padding: 10px 20px;
  height: auto;
  width: 80%;
  background-color: rgb(245, 244, 244);
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
}
.fristSection > div > h3 {
  font-size: 34px;
  font-weight: 400;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.fristSection > div > ol > li {
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", Georgia, "Times New Roman", Times, serif;
  margin: 5px 0px;
}
.fristSection > div > button {
  font-size: 16px;
  border: none;
  width: 110px;
  font-weight: 400;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin: 25px auto auto auto;
  padding: 4px;
  background-color: blue;
  border-radius: 5px;
  color: white;
  font-family: "Poppins";
}
/* Second Section Styles */
.secondSection {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttons {
  margin: 40px auto 10px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.next_btn {
  padding: 5px;
  width: 110px;
  border: none;
  background-color: blue;
  color: white;
  font-family: "Poppins";
  border-radius: 5px;
  font-size: 15px;
}
.back_Btn {
  padding: 5px;
  width: 110px;
  border: none;
  background-color: rgb(56, 56, 58);
  color: rgb(255, 255, 255);
  font-family: "Poppins";
  border-radius: 5px;
  font-size: 15px;
}
.ins {
  font-size: 34px;
  font-weight: 400;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.instruct {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 500;
  margin: 0px auto;
}
.camera {
  height: 200px;
  width: 350px;
  border-radius: 12px;
  overflow: hidden;
  margin: 10px auto 10px auto;
}
.accesscontrol {
  margin: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.secondSection > div {
  height: auto;
  padding: 10px 20px;
  width: 75%;
  border-radius: 12px;
  background-color: rgb(245, 244, 244);
}
.acceeslblswitch {
  height: 60px;
}
.acceeslblswitch > p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
  position: relative;
  background-color: antiquewhite;
  color: blue;
}

@media (max-width: 768px) {
  .main {
    flex-direction: column;
    height: auto;
  }

  .leftSide > main > section > div {
    height: 80px;
    width: 100%;
    margin: -20px auto auto auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .leftSide > main > section {
    display: flex;
    justify-content: space-around;
  }
  .leftSide {
    width: 100%;
    height: auto;
    font-family: "Poppins", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }
  .leftSide > div {
    width: 100%;
    height: auto;
  }
  .rightSide {
    height: auto;
    width: 100%;
  }
  .rightSide > div {
    height: auto;
    width: 100%;
  }
  .fristSection {
    height: auto;
    width: 100%;
  }
  .secondSection {
    height: auto;
    width: 100%;
  }
  .secondSection > div {
    height: auto;
    width: 100%;
  }
  .leftSide > main > div > p {
    font-size: 23px;
    text-align: center;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
  .leftSide > main > div {
    margin: 10px auto auto auto;
  }
  .fristSection > div {
    width: 100%;
    padding: 10px 15px;
  }
  .fristSection > div > h3,
  .ins {
    font-size: 28px;
    text-align: center;
  }
  .instruct {
    font-size: 17px;
    margin: 10px auto;
    text-align: center;
  }
  .accesscontrol {
    margin: 10px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .accesscontrol > div {
    font-size: 16px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .camera {
    height: 200px;
    width: 350px;
    border-radius: 15px;
    overflow: hidden;
    margin: 20px auto 10px auto;
  }
}

/* Navbar Styles */
.navbar {
  height: 76px;
  width: 100%;
  padding: 0px 100px;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo > h3 {
  font-family: "arvo";
  font-size: 30px;
  font-weight: 600;
  margin: auto 0px;
}
