.FirstLine_Address{
      display: flex;
      flex-direction: row;
      column-gap: 100px;
      width: 100%;
      margin-top: 20px;
}

.child_Address{
      display: flex;
      flex-direction: column;
      width:100%;
}

.child_input1, .child_input{
      width:100%;
     
}

.child_input1{
         border-style: none;
         border:1px solid gray;
         border-radius: 3px;
         height: 30px;
         padding: 3px;
}


.address_label{
      font-family: Poppins,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      font-weight: 500;
}

.remove_education{
      cursor: pointer;
}

.remove_education:hover{
      text-decoration: underline;
}