.instruction_container {
  width: 80vw;
  height: auto;
  border-radius: 15px;
  background-color: #f2f7ff;
}
.headings {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 20px;
  height: auto;
}
.headingsins {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-size: 19px;
  color: red;
}
.container {
  height: 45vh;
  width: 35%;
  border-radius: 20px;
}
.cam {
  border-radius: 20px;
}
.next_btn {
  font-size: 20px;
  border-style: none;
  border-radius: 10px;
  width: 100px;
  background-color: #ffde59;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  height: 50px;
  width: 130px;
}

.RecordingStop {
  border-style: none;
  border-radius: 10px;
  width: 80px;
  background-color: rgb(205, 54, 54);
  font-size: 20px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  margin:10px auto;
  height: 40px;
}
.RecordingStart {
  border-style: none;
  border-radius: 10px;
  width: 80px;
  height: 40px;
  font-size: 20px;
  color: white;
  background-color: rgb(78, 121, 159);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  margin:10px auto;
}

.recorderdvideo {
  width: 100%;
  border-radius: 20px;
  height: 40vh;
}
@media (max-width: 900px) {
  .container {
    height: 55vh;
    width: 55%;
    border-radius: 20px;
  }
}
@media (max-width: 600px) {
  .instruction_container {
    width: 95vw;
    height: auto;
    border-radius: 15px;
    background-color: #f2f7ff;
  }
  .headingsins {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    font-size: 15px;
    color: red;
    text-align: center;
  }
  .headings {
    display: flex;
    justify-content: center;
    margin: 0px auto 10px auto;
    font-size: 17px;
    height: auto;
  }
  .container {
    height: auto;
    width: 85%;
    border-radius: 20px;
  }
  .recorderdvideo {
    width: 100%;
    border-radius: 20px;
    height: auto;
    margin: auto;
  }
  .RecordingStart {
    width: 80px;
    height: 32px;
    margin: 10px auto;
  }
  .RecordingStop {
    margin: 10px auto;
    height: 32px;
  }
  .next_btn {
    font-size: 15px;
    border-radius: 10px;
    height: 35px;
    width: 90px;
    margin: 10px auto;
  }
}
