#btn {
  border-style: none;
  margin: 5px 5px 0 0;
  border-radius: 5px;
  background-color: hsl(0, 0%, 90%);
  font-weight: 600;
  padding: 0px 5px 0px 5px;
}

tr:nth-child(even) #btn {
  background-color: hsl(0, 0%, 90%);
}

tr:nth-child(odd) {
  background-color: aliceblue;
}

/* .questions  {
 

} */

.question_textarea {
  width: auto;
  max-width: 500px;
}

.question_addbtn {
  background-color: #ffde59;
  border-style: none;
  color: black;
  font-weight: 600;
  width: 100%;
}

.question_textarea {
  border-radius: 5px;
  height: 235px;
  width: 900px;
  border: 1px solid #ced4da;
  padding: 10px;
}

.label {
  text-align: left;
  border-style: none;
  margin: 5px 5px 0 0;
  border-radius: 5px;
  background-color: hsl(0, 0%, 90%);
  padding: 0px 5px 0px 5px;
  color: #ffffff;
}

.easy {
  background-color: #5cb85c;
}

.medium {
  background-color: #f0ad4e;
}

.hard {
  background-color: #d9534f;
}

.sorting {
  text-align: right;
  cursor: pointer;
}

.question_tags {
  border-style: none;
  margin: 5px 5px 0 0;
  border-radius: 5px;
  background-color: hsl(0, 0%, 90%);
  padding: 0px 5px 0px 5px;
}

.questions_select {
  width: 100%;
  color: #1b1642;
  
}

#searchtopics{
  position: relative;
}

.pages_btn {
  border-style: none;
  width: 50px;
}

.pages {
  position: fixed;
  top: 90%;
  left: 45%;
}

.activepage {
  color: #f0ad4e;
  font-weight: 700;
  font-size: large;
}

.options {
  margin: 50px 0 0 10px;
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.options_option {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vsbl {
  visibility: visible;
}

.none {
  display: none;
}

.spinner {
  width: 100px;
  height: 100px;
}

.questions .header {
  color: #ffffff;
  background-color: #1b1642;
}

.question_table {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  flex-direction: column;
  align-items: center;
  
}
.questions{
  width:100%;
}
.question_table table {
  font-family: "DM Sans", sans-serif;
  border-collapse: collapse;
  width: 90%;
}

.question_table th ~ .sorting {
  text-align: left;
}

.question_table th,
td {
  text-align: left;
  padding: 8px;
  height: 60px;
}

.question_table td:nth-child(1) {
  width: 10%;
}

.question_table td:nth-child(2) {
  width: 35%;
  overflow-wrap: break-word;
}


.question_table td:nth-child(3) {
  width: 15%;
}

.question_table td:nth-child(4) {
  width: 25%;
}

.question_table td:nth-child(5) {
  width: 15%;
}

.question_table thead {
  border-bottom: 1px solid;
}

.btn-fixed {
  position: fixed;
  bottom: 20px;
  right: 30px;
  border-radius: 50%;
}


.Coding{
  height:300px;

  max-height: 300px;
  min-height: 300px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  /* padding: 10px; */
}
.Simple{
  border-radius: 5px;
  height: 300px;

  border: 1px solid #ced4da;

  
}

.display{
  visibility: visible;
  height:120px;
  width:500px;
  max-height: 150px;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  padding: 10px;
}
.none{
  display: none;
}
.mcq_div{
  width: 500px;
}
.mcq_div_options{
  width: 500px;
  display: flex;
  justify-content: space-between;
}
.Options{
  margin-top: 5px;
}
.Mcq{
  border-radius: 5px;
  height: 300px;
  border: 1px solid #ced4da;

}

.questionselect{
  width: 150px;
  background-color: transparent;
  border: none;
  color: #000; /* Set the desired text color */
}
.questionselect option{
  background-color: rgba(255, 255, 255, 0.8);
}

.testcase {
  display: flex;

  /* justify-content: flex-end; */
}


.Sub_Test{
  width:100%;
  border:1px solid;
  height:50px;
  border-radius: 5px;
  
}

 #activepage{
  
  color:black;
  font-weight: 600;
  font-size: large;
  border:1px solid #f0ad4e;
  width:30px;
  height: 30px;
  text-align: center;
  background-color:#f0ad4e;
  border-radius: 5px;
  border-color: #1b1642;
 }
 #activepage:hover{
  text-decoration: underline;
  border-color: #1b1642;
  cursor: pointer;
 }

 .notactivepages{
  cursor: pointer;
  font-weight: 600;
 }
 .notactivepages:hover{
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
 }

 .nextprevbtn{
  border-style: none;
  border-radius: 5px;
  width: 50px;
 }
 .nextprevbtn:hover{
  border-color: #f0ad4e;
  background-color: #ffde59;
  border-radius: 5px;
  font-weight: 600;
 }

 .pagemain{
  position: fixed;
  bottom: 10px;
  padding: 10px;
  left:38vw;
  background-color:rgba(0, 0, 0, 0.25);
  height: 50px;
  border-radius: 5px;
 }

 .type_selection{
  background-color: #1b1642;
  color: #ffffff;
  font-weight: bold;
  border-style: none;
  cursor: pointer;
  width: 100%;
 }

 .fileuploadbutton{
  border: 1px solid;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
 }


 .question_title{
  width:100%;
  padding-left: 12px;
  margin-bottom: 10px;
  height: 40px;
 }