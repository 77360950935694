.main{
      width: 90%;
      margin: 100px auto 0 auto;
}
.submain_container{
      display: flex;
      /* flex-direction: column; */

      margin-top: 10px;
}


.editor{
      margin: 0 10px;
       border:1px solid;
      border-top:none;
}
.history{
      display: flex;
      justify-content: space-between;
      margin-top: 3px;
      /* border-top: 1px solid; */
      padding: 0 10px 5px 10px;
}

.submain_left{
      width: 65%;
      /* height: 50vh;   */
      /* border:1px solid; */
      /* border-top:none; */
      /* padding: 0 10px 0 10px; */
}

.history_sub{
      width: 300px;
      display: flex;
      /* justify-content: space-between; */
}

.timing{
      display: flex;
      justify-content:flex-end;
      width: 60%;
}

.history_label{
      display: flex;
      align-items: center;
      /* font-weight: 600; */
      font-size: 15px;
}

.question_label{
      height: 400px;
      border: 1px solid;
      border-top: none;
      padding: 0 10px;
}


.submain_right{
      width: 32%;
      margin: 0 auto;
}

.screenshots{
 display: flex;
 flex-wrap: wrap;
 justify-content: flex-start;
 gap: 5px;
 height:200px;
 overflow: auto;
}

.output{
      height: 400px;
      overflow: auto;
      border:1px solid;
      border-top:none;
      padding: 10px;
}

