/* Footer section  style */
.footer_wrapper {
      background-color: #08213e;
      height: auto;
      width: 100%;
      display: flex;
      margin: auto;
      justify-content: center;
      font-family: Poppins;
      padding-bottom: 5rem;
    }
    .footer_first {
      width: 22%;
      margin-top: 2rem;
      color: white;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    
    .footer_first p {
      font-size: 0.8rem;
      width: 85%;
      margin-top: 1rem;
      text-align: justify;
    }
    .logo_footer {
      background-color: white;
      width: 85%;
      height: 22%;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0.2rem 0.8rem;
    }
    .logo_footer img {
      width: 28%;
      height: 4rem;
      margin: 0.2rem 0.8rem;
    }
    .fullscreen{
      width: 100%;
    }
    .footer_second {
      height: auto;
      width: 25%;
      display: flex;
      flex-direction: column;
      margin-top: 3rem;
    }
    
    .footer_second h2 {
      font-size: 1.5rem;
      text-align: left;
      margin-left: 2rem;
      color: white;
      font-weight: 500;
      padding-bottom: 1rem;
    }
    .footer_second div {
      width: 90%;
      margin: auto 1rem;
      display: flex;
      color: white;
    }
    .footer_second a {
      width: 90%;
      margin: auto 1rem;
      display: flex;
      color: white;
      text-decoration: none;
    }
    
    .footer_second div p {
      margin-left: 0.9rem;
      font-size: 0.9rem;
    }
    .footer_second div img {
      width: 1.3rem;
      height: 1.2rem;
    }
    .footer_third {
      height: auto;
      width: 22%;
      margin-top: 3rem;
    }
    .footer_third .Contact_us {
      font-size: 1.5rem;
      font-weight: 500;
      color: white;
      text-align: left;
      margin-left: 0.2rem;
    }
    .footer_third div {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      color: white;
      margin: 0.2rem auto;
    }
    .footer_third div p {
      margin-left: 0.9rem;
      font-size: 0.9rem;
    }
    .footer_third div img {
      width: 1.7rem;
      height: 1.7rem;
      margin-top: -0.9rem;
    }
    .footer_forth {
      height: auto;
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      margin-top: 3.5rem;
      color: white;
    }
    .form{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
    .form input{
      height: 2.5rem;
      width: 60%;
      border-radius: 0.5rem;
      padding: 0rem 1rem;
      color: #08213e;
      margin: 0.5rem;
    }
    
    .footer_forth button {
      height: 2rem;
      width: 35%;
      border-radius: 0.5rem;
      background-color: #ffde58;
      font-weight: 500;
      color: #08213e;
      display: flex;
      align-items: center;
      margin: 0.8rem auto;
      justify-content: center;
    }
    .footer_forth h4 {
      font-weight: 400;
      font-size: 1.3rem;
    }
    .footer_forth div {
      width: 70%;
      display: flex;
      justify-content: space-around;
    }
    .footer_forth div img {
      width: 2rem;
      height: 2rem;
      cursor: pointer;
    }
    .footer_forth p {
      width: 60%;
      text-align: center;
      font-size: 1rem;
    }
    .gray_div {
      height: 3rem;
      width: 100%;
      background-color: #08213e;
      margin-top: 0rem;
    }
    
    .more_question {
      width: 100%;
      height: auto;
      display: flex;
      padding: 2rem auto;
      align-items: center;
      margin-bottom: 2rem;
    }
    .more_question div {
      width: 100%;
    }
    .more_question div h2 {
      font-size: 1.9rem;
      font-weight: 600;
      text-align: center;
    }
    .more_question div button {
      height: 3rem;
      width: 9rem;
      color: #ffde58;
      background-color: #08213e;
      margin-top: 1rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;
      margin: auto;
    }
    .more_question div button img {
      height: 1.3rem;
      width: 1.3rem;
    }
    /* Tablet View */
    @media (max-width: 1170px) {
      .footer_wrapper {
        background-color: #08213e;
        width: 100%;
        display: flex;
        margin: auto;
        height: auto;
        justify-content: center;
        padding-bottom: 5rem;
      }
      .logo_footer img {
        width: 50%;
        height: 2.5rem;
        margin: 0.2rem 0.8rem;
      }
      .footer_first {
        width: 22%;
        margin: 1rem;
        color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .logo_footer {
        background-color: white;
        width: 80%;
        height: 15%;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
      }
      .header{
            color: black;
            font-size: 1.3rem;
      }
      .footer_first p {
        font-size: 0.7rem;
        width: 85%;
        margin-top: 1rem;
        text-align: justify;
      }
      .footer_second {
        width: 23%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
      }
      .footer_second h2 {
        font-size: 1.2rem;
      }
      .footer_second div p {
        margin-left: 0.5rem;
        font-size: 0.7rem;
      }
      .footer_second div img {
        width: 1rem;
        height: 1rem;
      }
      .footer_third {
        width: 22%;
        height: auto;
        margin-top: 2rem;
      }
      .footer_third .Contact_us {
        font-size: 1.2rem;
        font-weight: 500;
        color: white;
        margin-left: 2rem;
      }
      .footer_third div {
        width: 95%;
        height: auto;
        display: flex;
        align-items: center;
        color: white;
        line-height: 0.9rem;
        margin-top: 0.6rem;
      }
      .footer_third div p {
        margin-left: 0.5rem;
        font-size: 0.7rem;
      }
      .footer_third div img {
        width: 1.3rem;
        height: 1.3rem;
      }
      .footer_forth {
        height: auto;
        width: 24%;
        display: flex;
        flex-direction: column;
        align-items: center;
       
        color: white;
        margin-right: 1rem;
      }
      .form input{
        height: 2.5rem;
        width: 60%;
        border-radius: 0.5rem;
        padding: 0rem 1rem;
        color: #08213e;
        margin: 0.4rem;
      }
    
      .footer_forth button {
        height: 1.3rem;
        width: 50%;
        border-radius: 0.5rem;
        background-color: #ffde58;
        font-weight: 500;
        text-align: center;
        font-size: 0.9rem;
        border: none;
      }
      .footer_forth h4 {
        font-weight: 400;
        font-size: 1rem;
      }
      .footer_forth div {
        width: 70%;
        display: flex;
        justify-content: space-around;
      }
      .footer_forth div img {
        width: 1.2rem;
        height: 1.2rem;
      }
      .footer_forth p {
        width: 60%;
        text-align: center;
        font-size: 0.7rem;
      }
    
      .more_question div button {
        height: 2.5rem;
        width: 8rem;
        color: #ffde58;
        background-color: #08213e;
        margin-top: 1rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;
      }
      .more_question div button img {
        height: 1.3rem;
        width: 1.3rem;
      }
    }
    /* -------------------------------- Mobile View ----------------------------------- */
    
    @media (max-width: 701px) {
      .footer_wrapper {
        background-color: #08213e;
        height: auto;
        width: 100%;
        display: flex;
        margin: auto;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1.5rem;
      }
      .logo_footer img {
        width: 85%;
        height: auto;
        margin: 0.2rem 0.8rem;
      }
      .footer_first {
        width: 85%;
        color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: auto;
      }
      .logo_footer {
        background-color: white;
        width: 45%;
        height: auto;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        margin: auto;
      }
      .footer_first p {
        font-size: 0.7rem;
        width: 85%;
        text-align: justify;
      }
      .footer_second {
        height: auto;
        width: 81%;
        display: flex;
        flex-direction: column;
      }
      .footer_second h2 {
        font-size: 1rem;
        color: #ffde58;
        margin-left: 1rem;
        margin-top: -2rem;
      }
      .footer_second div p {
        font-size: 0.7rem;
      }
      .footer_second div img {
        width: 1rem;
        height: 1rem;
      }
      .footer_third {
        height: auto;
        width: 81%;
      }
      .footer_third .Contact_us {
        font-size: 1rem;
        font-weight: 500;
        color: #ffde58;
        margin-top: -1.5rem;
        margin-left: 1rem;
      }
      .footer_third div {
        width: 90%;
        height: auto;
        display: flex;
        align-items: center;
        color: white;
        line-height: 0.9rem;
        margin-top: 0.2rem;
      }
      .footer_third div p {
        margin-left: 0.5rem;
        font-size: 0.7rem;
      }
      .footer_third div img {
        width: 1.1rem;
        height: 1.1rem;
      }
      .footer_forth {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
     
      }
      .form input{
        height: 2rem;
        width: 60%;
        border-radius: 0.5rem;
        padding: 0rem 1rem;
        color: #08213e;
        margin: 0.2rem;
        display: none;
      }
    
      .footer_forth button {
        height: 1.5rem;
        width: 37%;
        border-radius: 0.3rem;
        background-color: #ffde58;
        font-weight: 500;
        text-align: center;
        border: none;
        font-size: 0.8rem;
        
      }
      .footer_forth h4 {
        font-weight: 400;
        font-size: 1.4rem;
        margin-top: 0.5rem;
      }
      .footer_forth div {
        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 1rem;
      }
      .footer_forth div img {
        width: 1.7rem;
        height: 1.7rem;
      }
      .footer_forth p {
        width: 40%;
        text-align: center;
        font-size: 0.8rem;
        margin: 0.5rem auto;
        display: none;
        color: #ffde58;
      }
      .gray_div {
        height: 1rem;
        width: 100%;
        background-color: #d9d9d9;
        display: none;
      }
      .blue_div {
        height: 4rem;
        width: 100%;
        background-color: #08213e;
        display: none;
      }
      .more_question div h2 {
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
      }
      .more_question div button {
        height: 2rem;
        width: 25%;
        color: #ffde58;
        background-color: #08213e;
        margin-top: 1rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-size: 0.7rem;
      }
      .more_question div button img {
        height: 1rem;
        width: 1rem;
      }
    }