.navbar {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 60px;
  padding: 10px 15px;
  width: 81vw;
  position: fixed;
  z-index: 9;
  left: 265px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  margin-left: 16px;
}

.pageContent {
  margin-left: 18vw;
  width:100%;
}
.leftNavbar {
  display: flex;
  /* margin-left: 16px; */
  margin-top: 6px;
}
.leftNavbar > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.leftNavbar > span > img {
  height: 45px;
  width: 45px;
}
.leftNavbar > span > label {
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}
.rightNavbar {
  display: flex;
  align-items: center;
  /* margin-right: 30px; */
  cursor: pointer;
}


.h5div {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid gray; */
  border-radius: 6px;
  padding-inline: 12px;
  align-items: center;
}

.gridData {
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 16px;
  column-gap: 10vw;
  width: 95%;
  margin-top: 20px;
}

.h2 {
  font-family: Poppins;
  font-weight: 600;
  text-decoration: underline;
}
.mainSection {
  border: 1px solid gray;
  width: 77vw;
  padding: 25px;
  padding-top: 15px;
  border-radius: 10px;
}
.editInputDiv {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.education {
  border: 1px solid gray;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-inline: 1.4vw;
}

.skillsData {
  display: flex;
  /* flex-direction: column; */
  gap: 25px;
}
.skill {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid gray;
  border-radius: 14px;
  padding: 17px;
  align-items: center;
  padding-inline: 26px;
  flex-wrap: wrap;
}
.skill:hover {
  scale: 107%;
}
.commonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 1400px) {
  .pageContent {
    margin-left: 21vw;
  }
}

@media (max-width: 1200px) {
  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0;
    width: 100vw;
  }
  .pageContent {
    margin-left: 2vw;
  }
}
