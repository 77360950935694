.Main {
       background-image: radial-gradient(white,#c4f1f2,#7b94f4);
      background-size: cover;
      background-position: center;
      height: 100vh;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    overflow: hidden;
     
    }
    .bg2 {
     
      width: 520px;
      height: 305px;
      filter: blur(2px);
      position: absolute;
      bottom: 0;
      right: 0;
    }
    
    .logo {
      width: 107px;
      height: 107px;
    }
    
    .heading_logo_main {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .heading_logo {
      display: flex;
      align-items: center;
    }
    .heading_logo label {
      font-weight: 700;
      font-size: 40px;
    }
    .login_main {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 20px 0 20px 0;
    }
    .login_inputs {
     height: 70vh;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid;
      width: 500px;
      margin: 20px;
    
      border-radius: 20px;
      border-color: #ffffff;
      background-color: #ffffff73;
    }
    #login_title {
      font-weight: 400;
      font-size: 30px;
    }
    #create_account {
      cursor: pointer;
      color: blue;
      font-weight: 400;
    }
    #create_account:hover{
      color: red;
    }
    .create_account_main {
      text-align: center;
      font-size: 18px;
    }

    .submit_btn_text {
      font-size: 18px;
      font-weight: 400px;
      cursor: pointer;
    }
    .submit_btn {
      width: 120px;
      height: 45px;
    }
    .forget {
      font-size: 18px;
      font-weight: 400;
      cursor: pointer;
      color: #7c7878;
    }
    .form_group {
      width: 100%;
    }
    
    @media screen and (max-width: 600px) {
      .submit_btn {
        width: 100px;
        height: 50px;
      }
      .create_account_main {
        font-size: 15px;
        font-weight: 400;
        margin: 0 20px 0 20px;
      }
      .forget {
        font-size: 15px;
        font-weight: 400;
      }
      .form_group {
        width: 100%;
        height: 20px;
      }
      .submit_btn_text {
        font-size: 15px;
      }
    
      .logo {
        width: 50px;
        height: 50px;
      }
      .heading_logo label {
        font-weight: 700;
        font-size: 25px;
      }
    }
    

    .password_validatior{
      position: absolute;
      top: 100%;
      background-color: white;
      color: black;
      font-size: 13px;
      padding: 5px;
     border: 1px solid black;
      border-radius: 10px;
      font-weight: 600;
      z-index: 999;
    }
    
    