.questionmodal {
  max-width: 90vw;
  margin: 0 5vw;
  margin-top: 60px;
  overflow: auto;

}
.modal-content {
  width: 90vw;
}

.selectionquestion {
  border-style: none;
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #08213e;
  color: white;
  cursor: pointer;
}
.recordingsmain{
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
}
.screenRecords{
      width:550px;
      height: 250px;
      object-fit: cover;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      overflow: auto;
     
}
.screenRecords2{
  width:550px;
  height: 400px;
  object-fit: cover;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: auto;
 border:1px solid black
}


.camvideo{
      width: 70%;
      height: 250px;
      object-fit:cover;
      border-radius: 5px;
}
.transcribe{
  width: 95%;
  border:1px solid;
  border-radius: 15px;
  padding: 10px;
  height: auto;
  max-height: 110px;
  overflow: auto;
}

.transcriptheading{
  color: #000;

font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.output{
  width: 95%;
  overflow: auto;
  /* padding: 10px; */
  height: auto;
  max-height: 150px;
  border-radius: 5px;
  border: 1px solid;
  margin-top: 5px;
}
.runbtn{
  display: inline-flex;
/* padding: 5px 20px; */
justify-content: center;
align-items: center;
/* gap: 10px; */
/* border-radius: 10px; */
border: 1px solid #DFDFDF;
background: #08213E;
color: white;
margin-left: 250px;
}

.returnbtn{
  display: inline-flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background: #08213E;
  color: white;
  
}

.feedbacktextarea{
 height: auto;
 min-height: 70px; 
padding: 10px;
border-radius: 10px;
border: 1px solid #A8A8A8;
background: #FFF;
width: 500px;
}

.feedback_point{
  display: flex;
  align-items: center;
  column-gap: 30px;
  flex-wrap: wrap;
}

.point{
  color: #000;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.pointinput{
  display: inline-flex;
justify-content: center;
align-items: center;

background: #FFF;
text-align: right;
width: 100px;
height: 30px;
margin-left: 5px;
padding-right: 10px;
}

.mcqcontainer{
  display: flex;
  flex-direction: column;
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
}


.htmlmodal .modal-dialog {
  width: 100vw;
  margin: 0;
}

.htmlmodal .modal-content {
  width: 100%;
}

.htmlmodal .modal-body {
  max-height: calc(100vh - 120px); /* Adjust as needed based on your content */
  overflow-y: auto;
}

.editor{
  border:1px solid black;
  border-radius: 5px;
}