@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@700&family=Lobster&family=Nunito:wght@300;400;500;700;800;900&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto+Slab:wght@200;300;400;500;600;700;800&family=Roboto:ital,wght@0,300;0,900;1,400&display=swap");

.main {
  width: 95%;
  background-color: #f2f2f2;
  height: auto;
  margin: 80px auto auto auto;
  min-height: 100vh;
}
.navigation {
  margin: 20px 30px;
}
.userDetails {
  height: 100px;
  background-color: white;
  width: 96.7%;
  border-radius: 20px;
  border: 1px solid #a8a8a8;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}
.userDetails div {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 100px;
  padding-left: 20px;
  flex-direction: column;
  border-right: 2px solid #ebebeb;
}
.userDetails div label {
  font-size: 17px;
}
.userDetails div label > img {
  height: 25px;
  width: 25px;
  transform: rotate(90deg);
  cursor: pointer;
}
.userDetails div span {
  font-size: 14px;
  color: #727272;
  font-family: "Nunito";
}
.popup {
  width: 204px;
  height: 82.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup p {
  width: 80%;
  margin: auto;
  padding: 2px 4px;
  font-size: 16px;
  cursor: pointer;
}
.popup p > img {
  padding-right: 9px;
}
.cancel {
  float: right;
  margin-right: 40px;
  margin-top: 0px;
  color: #a19e9e;
  cursor: pointer;
  font-size: 16px;
  text-decoration: underline;
  display: none;
}
.apply {
  visibility: hidden;
  margin-top: 0px;
  display: none;
}
/* Card Styles */
.cards {
  width: 100%;
  margin: 25px auto 0px auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 30px;
  align-items: center;
  justify-content: space-around;
}
.card {
  background-color: #ffffff;
  width: 30%;
  height: 196px;
  margin: 12px 0px;
  border: 1px solid #a8a8a8;
  border-radius: 20px;
  cursor: pointer;
}
.card > label {
  margin: 14px auto 0px 25px;
  color: #a8a8a8;
  font-family: "Nunito";
  font-size: 16px;
  justify-content: space-between;
  display: flex;
}
.card label span {
  margin-right: 20px;
  margin-top: 5px;
}
.card label span input {
  height: 19px;
  width: 19px;
}
/* .card p {
  width: 87%;
  margin: auto;
  color: #000;
  font-family: "Nunito";
  font-size: 18px;
  height: 70px;
} */
.card div {
  display: flex;
  margin: auto;
  width: 87%;
  justify-content: space-between;
  align-items: center;
}
.card section {
  display: flex;
  width: 90.5%;
  margin: 29px auto auto 25px;
  justify-content: space-between;
}
.card section > label {
  font-size: 14px;
}
.minPoint {
  color: #00a21a;
  font-size: 15px;
}
.maxPoint {
  font-size: 16px;
  color: #00a21a;
}

.fail {
  font-size: 16px;
  color: red;
}
/* Feedback Modal Styles */
.dropDown {
  width: 190px;
  height: 40px;
  padding: 2px 0px 2px 7px;
  font-size: 17px;
  border-radius: 30px;
  background-color: #08213e;
  color: white;
  font-weight: 500;
}
.dropDown > option {
  font-weight: 400;
}
.dropDown {
  outline: none;
}
.feedbackBox {
  width: 97%;
  height: auto;
  overflow-y: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.feedbackBox > label {
  font-size: 17px;
  margin: 10px auto 10px 15px;
}
.feedbackBox > div {
  height: auto;
  max-height: 150px;
  width: 98%;
  padding: 7px 13px;
  /* border: 1px solid #a8a8a8; */
  border-radius: 15px;
  color: #535252;
  font-family: Nunito;
  font-size: 17px;
  overflow-y: auto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.coding_container {
  display: flex;
  column-gap: 15px;
  height: auto;
  overflow: hidden;
  min-height: 250px;
}
.explanation_main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 18px;
  font-family: "Nunito", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
  font-weight: 500;
  color: #535252;
  font-size: 17px;
}
.coding_editor {
  overflow: auto;
  width: 50%;
}
.code_show {
  /* border: 1px solid; */
  padding: 10px;
  /* border-radius: 5px; */
  background-color: #f2f2f2;
  overflow: auto;
  height: auto;
  min-height: 200px;
}
.feedbackBox > div:nth-child(4) {
  border: none;
  width: 100%;
  display: flex;
  overflow-y: hidden;
  justify-content: space-between;
  align-items: center;
}
/* .feedbackBox > div > div {
  height: auto;
  max-height: 100px;
  width: 72%;
  padding: 7px 10px;
  border: 1px solid #a8a8a8;
  border-radius: 10px;
  overflow-y: auto;
} */

.feedback_container {
  height: auto;
  max-height: 100px;
  width: 72%;
  padding: 7px 10px;
  border: 1px solid #a8a8a8;
  border-radius: 10px;
  overflow-y: auto;
}
.feedbackBox > div > label {
  font-size: 15px;
  color: #000;
}
.feedbackBox > div > label > span {
  padding: 5px 18px;
  border-radius: 10px;
  margin-left: 10px;
  border: 1px solid #a8a8a8;
}
.feedbackBox > button {
  padding: 6px 15px;
  border: none;
  width: 100px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #08213e;
  color: white;
  margin: 20px auto 5px auto;
}
/* Over all feedback */
.overAllFedback {
  display: flex;
  flex-direction: column;
  width: 97%;
  align-items: center;
  justify-content: center;
  padding: 0px 10px 10px 10px;
  background-color: white;
  margin-top: 20px;
  border: 1px solid #a8a8a8;
  border-radius: 10px;
}
.overAllFedback > label {
  font-size: 19px;
  font-weight: 600;
  margin: 10px auto;
}
.overAllFedback > div {
  display: flex;
  width: 95%;
  justify-content: space-around;
}
.overAllFedback > div > label {
  font-size: 16px;
}
.overAllFedback > div > label > span {
  width: 150px;
  font-size: 14px;
  padding: 3px 15px;
  border-radius: 10px;
  background-color: #08213e;
  color: white;
  margin-left: 15px;
}
.overAllFedback > div:nth-child(3) {
  display: flex;
  width: 95%;
  margin: auto;
  flex-direction: column;
  align-items: center;
  height: 120px;
  border: 1px solid #a8a8a8;
  margin-top: 20px;
  justify-content: space-around;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 10px;
}
.overAllFedback > div:nth-child(3) > label {
  font-size: 18px;
  text-decoration: underline;
}
.overAllFedback > div:nth-child(3) > span {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
}
@media (max-width: 800px) {
  .card {
    background-color: #ffffff;
    width: 40%;
    height: 196px;
    margin: 12px 0px;
    border: 1px solid #a8a8a8;
    border-radius: 20px;
    cursor: pointer;
  }
  .card > label {
    margin: 14px auto 0px 25px;
    color: #a8a8a8;
    font-family: "Nunito";
    font-size: 16px;
    justify-content: space-between;
    display: flex;
  }
  .card label span {
    margin-right: 20px;
    margin-top: 5px;
  }
  .card label span input {
    height: 19px;
    width: 19px;
  }
  .card p {
    width: 87%;
    margin: auto;
    color: #000;
    font-family: "Nunito";
    font-size: 18px;
    height: 70px;
  }
  .card div {
    display: flex;
    margin: auto;
    width: 87%;
    justify-content: space-between;
    align-items: center;
  }
  .card section {
    display: flex;
    width: 90.5%;
    margin: 29px auto auto 25px;
    justify-content: space-between;
  }
  .card section > label {
    font-size: 16px;
  }
  .minPoint {
    color: #828282;
    font-size: 15px;
  }
  .maxPoint {
    font-size: 16px;
    color: #00a21a;
  }

  .fail {
    font-size: 16px;
    color: red;
  }
}
@media (max-width: 500px) {
  .card {
    background-color: #ffffff;
    width: 90%;
    height: 196px;
    margin: 12px 0px;
    border: 1px solid #a8a8a8;
    border-radius: 20px;
    cursor: pointer;
  }
  .card > label {
    margin: 14px auto 0px 25px;
    color: #a8a8a8;
    font-family: "Nunito";
    font-size: 16px;
    justify-content: space-between;
    display: flex;
  }
  .card label span {
    margin-right: 20px;
    margin-top: 5px;
  }
  .card label span input {
    height: 19px;
    width: 19px;
  }
  .card p {
    width: 87%;
    margin: auto;
    color: #000;
    font-family: "Nunito";
    font-size: 18px;
    height: 70px;
  }
  .card div {
    display: flex;
    margin: auto;
    width: 87%;
    justify-content: space-between;
    align-items: center;
  }
  .card section {
    display: flex;
    width: 90.5%;
    margin: 29px auto auto auto;
    justify-content: space-between;
  }
  .card section > label {
    font-size: 16px;
  }
  .minPoint {
    color: #828282;
    font-size: 15px;
  }
  .maxPoint {
    font-size: 16px;
    color: #00a21a;
  }

  .fail {
    font-size: 16px;
    color: red;
  }
}

.question {
  height: auto;
  /* max-height: 200px; */
  text-align: left;
  width: 50%;
  overflow: auto;
  font-size: 15px;
  font-family: "Nunito";
  /* margin-right: 15px; */
  /* line-height: 14px; */
}

.question > p {
  margin-top: 0;
  /* height: 5px; */
}

.question1 {
  height: 90px;

  overflow: auto;
  font-size: 15px;
  margin: 0 25px;
  font-family: "Nunito";
}

.viewfeedback {
  border: 0.5px solid lightgray;
  border-radius: 5px;
  padding: 3px 6px;
  background-color: #08213e;
  color: white;
}
