
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.DashboardBox {
  display: flex;
  /* width: 280px; */
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}
.menuItem {
  display: none;
}
.userProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 20px 0px;
  margin: 20px auto;
  width: 100%;
}

.userProfile > img {
  height: 75px;
  width: 70px;
  border-radius: 50%;
}
.userProfile > label {
  margin-top: 10px;
  font-size: 20px;
  color: rgb(255, 255, 255);
}
.userProfile > span {
  font-size: 14px;
  color: rgb(255, 255, 255);
}
@media (max-width: 1000px) {
  .DashboardBox {
    display: none;
  }
  .menuItem {
    display: flex;
  }
}


.menuitems{

  font-family: "Poppins",'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 500;
  font-size: 14px;
}


.submenu_user{
  display: flex;
  flex-direction: column;
}

.submenu_profile{
  display: flex;
  align-items: center;
  width: 95%;
  margin: auto;
  font-size: 15px;
  cursor: pointer;
  column-gap: 20px;
  padding: 5px 9px;
}

.submenu_profile:hover{
  background-color: rgba(224, 222, 222, 0.5);
  border: 1px solid inherit;
  padding: 5px 9px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 95%;
  margin: auto;
  font-size: 15px;
  cursor: pointer;
  column-gap: 20px;

}

.menu_label{
  cursor: pointer;
  font-weight: 500;
}