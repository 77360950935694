

@font-face {
  font-family: 'DM Serif';
  src: url('../fonts/DMSerifDisplay-Regular.ttf') format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../fonts/DMSans-Regular.ttf') format('truetype');
  font-display: block;
}

html {
  font-family: 'DM Sans', sans-serif;
  color: #1b1642;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'DM Serif', sans-serif;
  font-weight: normal;
}



/* footer */
footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: rgba(255, 255, 255, .6);
  background-color: #1b1642;
  padding: 5rem 5rem;
  font-weight: normal;
  font-size: 1.125rem;
}

footer div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

footer span {
  display: flex;
  gap: 10px;
}

footer h4 {
  color: white;
  font-size: 1.75rem;
}

footer strong {
  color: white;
  font-size: .875rem !important;
  letter-spacing: 2px;
  font-weight: bold;
}

footer a {
  color: rgba(255, 255, 255, .6);
  text-decoration: none;
}

footer .contact {
  color: #6658ea;
}

footer img:hover{
  opacity: 0.8;
}

@media screen and (max-width:650px) {
  footer {
    padding: 3rem 3rem;
    gap: 50px;
  }

  footer .icons {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center !important;
  }
}

/* Home */

.home main {
  background-color: #ececfc;
  font-family: 'DM Sans';
  padding: 2rem 2rem;
  font-size: 20px;
  gap: 100px;
}

.home .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.home button {
  background-color: #0c243c;
  color: white;
  text-align: center;
  padding: 0.6rem 0.8rem;
  font-weight: 600;
  border-radius: 5px;
  border: none;
}

.home button:hover {
  opacity: 0.8;
}

.home h2 {
  font-size: 50px;
 
}

.library {
  background-color: rgb(234, 255, 224);
  color: #1b1642;
  padding: 4rem 4rem;
  text-align: center;
  font-family: 'DM Sans';
  font-size: 20px;
}
.library figcaption{
  font-weight: 600;
}

.library h3 {
  font-size: 50px;
}

.library div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}


.library img{
  cursor: pointer;
}
.library img:hover{
  position: relative;
  top: -5px;
  opacity: 0.8;
}

@media screen and (max-width:700px) {
  .library img{
    width: 100px;
    height: 100px;
  }
  .library div {
    gap: 20px;
  }
}