@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@700&family=Poppins:wght@100;500&display=swap");
.main {
  margin-top: 0px;
}
.sliderBox {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #f2f5f9;
}
.right {
  width: 85%;
  overflow: auto;
}
.rightNavbar {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.rightNavbar > div {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  background-color: #c9c6c6;
  cursor: pointer;
}
.admins_label{
  font-family: "Poppins",'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-weight: 500;
  margin-top: 20px;
}
.navbar {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  height: 60px;
  padding: 10px 15px;
  width: 82%;
  position: fixed;
  z-index: 1;
 float: right;
  overflow: hidden;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
} 
.notice{
  font-size: 12px;
}
.leftNavbar {
  display: flex;
  margin-left: 16px;
  margin-top: 6px;
}
.leftNavbar > span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.leftNavbar > span > img {
  height: 45px;
  width: 45px;
}
.leftNavbar > span > label {
  font-size: 25px;
  font-weight: 600;
  cursor: pointer;
}
.headings {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  width: 90%;
  font-family: Poppins;
}
.heading {
  font-family: Poppins;
}

.createbtn {
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
}
.groupmain {
  display: flex;
  width: 95%;
  margin:auto;
  column-gap: normal;
   height: auto; 
   min-height: 70vh;
  overflow-y: auto;
  flex-wrap: wrap;
  /* margin: 70px auto auto auto; */
  justify-content: flex-start;
}

.searchbar{
  display: flex;
  justify-content: space-between;
  width: 95%;
  flex-wrap: wrap;
  flex-basis: 1;
  margin:80px auto 20px auto;
  /* padding: 0px 100px 0px 30px; */
}

.search_input{
  width:300px;
}

.cardmain {
  width: 250px;
  height: 180px;
  border-radius: 10px;
  border: 1px solid #aaa;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin: 10px;
}

.groupname {
  color: #030303;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 10px;
}
.menu {
  height: 30px;
}

.groupname_and_icon {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 90%;
  margin: auto;
  min-height: 100px;

  align-items: flex-start;
}
.groupdetails {
  display: flex;
  width: 90%;
  margin: auto;
}
.leftlabels,
.rightdetails {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.leftlabels {
  color: #aaa8a8;

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.rightdetails {
  color: #3a3a3a;
  font-family: Poppins,'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.group_labels{
font-size: 15px;
font-family: "Poppins",'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.start_Date{
  width: 100%;
  height: 18px;
  font-size: 12px;
}
.uploadfile {
  font-size: 25px;
  margin-right: 15px;

  text-align: center;
}

.groupname_main {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.groupname_input {
  border-style: none;
  border: 1px solid;
  border-radius: 5px;
  height: 35px;
}

.titles {
  color: #3a3a3a;

  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
@media (max-width: 1200px) {
  .rightNavbar {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .right {
    width: 100%;
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    height: 60px;
    padding: 10px 15px;
    width: 100%;
    top: 0;
    position: fixed;
    z-index: 1;
    left: 0;
    overflow: hidden;
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25); */
  }
  .line {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .cardmain {
    width: 90%;
    height: auto;
    border-radius: 10px;
    border: 1px solid #aaa;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    padding-bottom: 10px;
    margin: 10px auto;
  }
  .groupmain {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .search_input{
    width:200px;
    margin-bottom: 10px;
  }
}
